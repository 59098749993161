<template>
  <div class="md-layout md-gutter">
    <div class="md-layout-item">
      <vue-table
        v-if="vTable.headers"
        ref="vtable"
        :values="vTable.values"
        :headers="vTable.headers"
        :actions="vTable.actions"
        :options="{
          tableClass:'table'
        }"
        :hidden-filters="true"
        @onToProfile="onToProfile"
      />
    </div>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';

export default {
  components: {
    VueTable,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    hiddenColumns: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'student.full_name',
            mask: 'name',
          },
          {
            title: 'student.student_number',
            mask: 'student number',
          },
          {
            title: 'student.email',
            mask: 'email',
          },
          {
            title: 'status',
          },
          {
            title: 'attendance.attendance_rate',
            mask: 'attendance',
            subfix: '%',
          },
          {
            title: 'activeEnrolment.classroom.classroom_code',
            mask: 'classroom',
          },
          {
            title: 'start_date',
            mask: 'start date',
            dateFormat: true,
          },
          {
            title: 'tuition_finish_date',
            mask: 'finish date',
            dateFormat: true,
          },
          {
            title: 'days_left',
            mask: 'days left',
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Student Profile',
            callback: 'onToProfile',
            icon: 'person',
          },
        ],
        values: {},
      },
    };
  },
  watch: {
    data(val) {
      this.vTable.values = { data: val };
    },
  },
  mounted() {
    if (this.hiddenColumns) {
      const aux = [];
      this.vTable.headers.forEach((item) => {
        this.hiddenColumns.forEach((column) => {
          if (column !== item.title) {
            aux.push(item);
          }
        });
      });

      this.vTable.headers = aux;
    }

    this.vTable.values = { data: this.data };
  },
  methods: {
    onToProfile(item, isMiddleClick) {
      this.openLink({
        name: 'StudentResume',
        params: { id: item.student_id },
      }, isMiddleClick);
    },
  },
};
</script>

<style scoped>
</style>
