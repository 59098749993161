<template>
  <div class="floating-filters">
    <div class="d-flex">
      <form-date
        v-model="filters.date"
        :disabled-dates="{ weekdays: [1,3,4,5,6,7] }"
        :min-date="new Date()"
        name="date"
        label="Date"
        class="mr-4"
      />

      <md-button
        class="md-primary md-just-icon md-round md-sm mr-1"
        @click="filter(false)"
      >
        <md-tooltip>Search</md-tooltip>
        <md-icon>search</md-icon>
      </md-button>

      <md-button
        class="md-primary md-just-icon md-round md-sm mr-1"
        @click="filter(true)"
      >
        <md-tooltip>Search and Download</md-tooltip>
        <md-progress-spinner
          v-if="fileLoad"
          :md-diameter="30"
          :md-stroke="3"
          class="md-white"
          md-mode="indeterminate"
        />
        <md-icon v-else>
          cloud_download
        </md-icon>
      </md-button>

      <md-button
        class="md-primary md-just-icon md-round md-sm mr-3"
        @click="openFilters = !openFilters"
      >
        <md-tooltip>Open More Filters</md-tooltip>
        <md-icon>filter_alt</md-icon>
      </md-button>
    </div>
    <div
      v-if="openFilters"
      class="more-filters"
    >
      <div>
        <form-select
          v-model="filters.sort_by"
          icon="sort"
          label="Sort By"
          :options="[
            { id: 'classroom_code', name: 'Classroom Code' },
            { id: 'available', name: 'Available Slots' },
            { id: 'enrolled', name: 'Enrolled' },
            { id: 'pre_enrolled', name: 'Pre-Enrolled' },
            { id: 'holidays', name: 'Holidays' },
            { id: 'inactive', name: 'Inactive' },
            { id: 'attendance_percentage', name: 'Attendance Average' },
            { id: 'finishing_15', name: 'Finishing 1-15 days' },
            { id: 'finishing_30', name: 'Finishing 16-30 days' },
          ]"
        />
        <form-select
          v-model="filters.sort_dir"
          class="sm-filter"
          icon="sort"
          label="Sort Dir"
          :options="[
            { id: 'ASC', name: 'ASC' },
            { id: 'DESC', name: 'DESC' },
          ]"
        />
      </div>
      <div>
        <PeriodsSelect
          v-model="filters.class_schedule_id"
          class="w-100"
          all-option="All"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  FormDate,
  FormSelect,
} from '@/components/Inputs';
import { PeriodsSelect } from '@/components/Inputs/selects';
import dateHelper from '@/utils/dateHelper';

export default {
  components: {
    FormDate,
    FormSelect,
    PeriodsSelect,
  },
  data: () => ({
    fileLoad: false,
    openFilters: true,
    filters: {
      date: dateHelper.getNextWeekday(1),
      download: false,
      sort_by: 'classroom_code',
      sort_dir: 'ASC',
      class_schedule_id: 'all',
    },
  }),
  methods: {
    filter(download) {
      this.filters.download = download;
      this.$emit('onFilter', this.filters);
    },
    fileLoading(val) {
      this.fileLoad = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-filters {
  position: fixed;
  top: 55px;
  right: 30px;
  border-radius: 1em;
  background: white;
  z-index: 2;
  -webkit-box-shadow: 3px 9px 21px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 3px 9px 21px -4px rgba(0,0,0,0.75);
  box-shadow: 3px 9px 21px -4px rgba(0,0,0,0.75);
  padding: 10px 0 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .more-filters {
    border-top: 1px solid #ccc;
    padding-top: .75em;
    margin-top: .75em;
    margin-right: 1em;
    display: flex;
    flex-direction: column;

    div{
      display: flex;
    }

    .sm-filter {
      max-width: 160px;
    }
  }
}
</style>
<style>
.md-progress-spinner.md-theme-default.md-white .md-progress-spinner-circle {
  stroke: white !important;
}
</style>
