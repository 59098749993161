<template>
  <vue-modal
    ref="modal"
    size="lg"
    @close="close"
  >
    <template slot="title">
      <b>{{ classroom.classroom_code }} - {{ classroom.classroom_name }}</b>
    </template>
    <template slot="body">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <md-table
            v-if="classroom.students.length"
            v-model="sortedStudents"
            table-header-color="green"
          >
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
            >
              <md-table-cell md-label="Student Number">
                {{ item.student_number }}
              </md-table-cell>
              <md-table-cell md-label="Name">
                {{ item.student_name }}
              </md-table-cell>
              <md-table-cell md-label="Email">
                {{ item.student_email }}
              </md-table-cell>
              <md-table-cell md-label="Status">
                {{ item.status }}
              </md-table-cell>
              <md-table-cell md-label="Tuition Finish Date">
                {{ item.finish_date }}
              </md-table-cell>
              <md-table-cell md-label="Days Left">
                {{ item.days_left }}
              </md-table-cell>
              <md-table-cell md-label="Attendance">
                {{ parseFloat(item.attendance).toFixed(2) }}%
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
    </template>
    <template slot="footer" />
  </vue-modal>
</template>
<script>
export default {
  props: {
    classroom: {
      type: Object,
      default: null,
    },
  },
  computed: {
    sortedStudents() {
      const stud = this.classroom.students.filter(() => true);
      return stud.sort((a, b) => a.days_left - b.days_left);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style></style>
