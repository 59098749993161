<template>
  <div>
    <md-card>
      <md-card-header class="pb-0">
        <div
          :class="{
            'md-title': true,
            'text-red': item.available <= 0,
            'text-orange': item.available <= 5,
            'text-green': item.available > 5
          }"
        >
          {{ item.classroom_code }}
          <md-button
            class="md-primary md-just-icon md-round md-sm pull-right"
            @click="showModal = true"
          >
            <md-icon>search</md-icon>
          </md-button>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout text-left">
          <div class="md-layout-item">
            <b>Available:  <md-chip
              class="m-0"
              :class="{
                red: item.available <= 0,
                orange: item.available <= 5,
                green: item.available > 5
              }"
            >{{ item.available }}</md-chip></b><br>
            <b>Limit: {{ item.limit }}</b><br>
            <b>Enrolled: {{ item.enrolled }}</b><br>
            <b>Pre-Enrolled: {{ item.pre_enrolled }}</b><br>
            <b>Holidays: {{ item.holidays }}</b><br>
            <b>Inactive: {{ item.inactive }}</b><br>
            <b>Attendance Average: {{ item.attendance_percentage }}</b><br>

            <md-divider />

            <b>1-15 days:
              <md-chip class="red m-0">{{ item.finishing_15 }}</md-chip>
            </b> <br>
            <b>16-30 days:
              <md-chip class="orange m-0">{{ item.finishing_30 }}</md-chip>
            </b>

            <md-divider />

            <a
              href="#"
              @click.prevent="showNationalities = !showNationalities"
            ><b>Show/Hide Nationalities</b></a>

            <div
              v-if="showNationalities"
            >
              <p v-if="!item.nationalities.length">
                No Students in this Classroom
              </p>
              <p
                v-for="n of item.nationalities"
                :key="n.nationality"
                class="nationality"
              >
                <img
                  :src="n.flag"
                  class="flag"
                >

                <b>{{ n.nationality }}</b>

                {{ n.percentage }}
              </p>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <class-detail
      v-if="showModal"
      :classroom="item"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import ClassDetail from './ClassDetail.vue';

export default {
  components: {
    ClassDetail,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    showModal: false,
    showNationalities: false,
  }),
  computed: {
    classColor() {
      let color = '';

      switch (true) {
        case this.item.available === 0:
          color = 'text-red';
          break;
        case this.item.available < 5:
          color = 'text-orange';
          break;
        case this.item.available > 5:
          color = 'text-green';
          break;
        default:
          color = '';
          break;
      }

      return color;
    },
  },
};
</script>

<style scoped>
.green {
  background-color: green !important;
}
.orange {
  background-color: orange !important;
}
.red {
  background-color: red !important;
}
.text-green {
  color: green !important;
}
.text-orange {
  color: orange !important;
}
.text-red {
  color: red !important;
}
.nationality {
  margin: 0;
}
.nationality .flag {
    width: 25px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 10px;
}
.md-divider {
  margin: 10px 0;
}
</style>
