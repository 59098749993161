/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
const moment = require('moment');

function dateParser(object, format = null) {
  const dateReg = /^\d{4}([./-])\d{2}\1\d{2}$/; // regex for 'YYYY-MM-DD'
  const obj = object;

  if (!obj) return;

  if (format) {
    for (const a of Object.keys(obj)) {
      if (obj[a] instanceof moment) {
        obj[a] = obj[a].format(format);
      } else if (typeof obj[a] === 'object') {
        dateParser(obj[a], format);
      }
    }
  } else {
    for (const a of Object.keys(obj)) {
      if (typeof obj[a] === 'string' && obj[a].match(dateReg)) {
        obj[a] = moment(obj[a]).startOf('day'); // add .startOf('day') to obtain always Dates at 00:00 hs
      } else if (typeof obj[a] === 'object') {
        dateParser(obj[a]);
      }
    }
  }
}

export default {
  getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(+d);
    d.setHours(0, 0, 0, 0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // Get first day of year
    const yearStart = new Date(d.getFullYear(), 0, 1);
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getFullYear(), weekNo];
  },
  weeksInYear(year) {
    const month = 11;
    let day = 31;
    let week;

    // Find week that 31 Dec is in. If is first week, reduce date until
    // get previous week.
    do {
      const d = new Date(year, month, day--);
      week = this.getWeekNumber(d)[1];
    } while (week === 1);

    return week;
  },
  getNextWeekday(dayINeed, baseDate = null) {
    const today = (baseDate || moment()).clone().isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (today <= dayINeed) {
      // then just give me this week's instance of that day
      return (baseDate || moment()).clone().isoWeekday(dayINeed);
    }
    // otherwise, give me *next week's* instance of that same day
    return (baseDate || moment()).add(1, 'weeks').clone().isoWeekday(dayINeed);
  },
  getLastWeekday(dayINeed, baseDate = null) {
    const today = (baseDate || moment()).clone().isoWeekday();

    // if we have passed the day of the week that I need:
    if (today >= dayINeed) {
      // then give me last week's instance of that day
      return (baseDate || moment()).clone().isoWeekday(dayINeed);
    }
    // otherwise, give me the instance of that same day
    return (baseDate || moment()).clone().subtract(1, 'weeks').isoWeekday(dayINeed);
  },
  dateParser,
};
