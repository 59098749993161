<template>
  <div>
    <md-progress-spinner
      v-if="data === null"
      :md-diameter="30"
      :md-stroke="3"
      md-mode="indeterminate"
    />

    <tabs
      :tab-name="[
        'Students finishing this week',
        'Students starting next week',
        'Students on holidays',
        'Inactive students',
        'Not Enrolled students',
      ]"
      color-button="success"
    >
      <template slot="tab-pane-1">
        <generic-students-table
          :data="data.finishing_this_week"
          :hidden-columns="['start_date']"
        />
      </template>

      <template slot="tab-pane-2">
        <generic-students-table :data="data.starting_next_week" />
      </template>

      <template slot="tab-pane-3">
        <generic-students-table
          :data="data.on_holidays"
          :hidden-columns="['start_date']"
        />
      </template>

      <template slot="tab-pane-4">
        <generic-students-table
          :data="data.inactive"
          :hidden-columns="['start_date']"
        />
      </template>

      <template slot="tab-pane-5">
        <generic-students-table
          :data="data.not_enrolled"
          :hidden-columns="['start_date']"
        />
      </template>
    </tabs>
  </div>
</template>

<script>
import { Tabs } from '@/components';
import GenericStudentsTable from './GenericStudentsTable.vue';

export default {
  components: {
    Tabs,
    GenericStudentsTable,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped>
  ::v-deep .tab-content,
  ::v-deep [class^="tab-pane"] { width: 100%}
</style>
