<template>
  <div>
    <!-- Filters -->
    <contingency-filters
      ref="filters"
      @onFilter="getClasses"
    />

    <template v-if="!loading">
      <div
        class="md-layout md-gutter"
      >
        <div class="md-layout-item">
          <md-card>
            <md-card-content>
              <div class="md-layout text-left">
                <div class="md-layout-item">
                  <b>Available:
                    <md-chip class="green">{{ total.available }}</md-chip>
                  </b> <br>
                  <b>Starting Next Week:
                    <md-chip class="green">{{ total.starting_next_week }}</md-chip>
                  </b> <br>
                  <b>Finishing This Week:
                    <md-chip class="red">{{ total.finishing_this_week }}</md-chip>
                  </b> <br>
                  <b>Finishing Next Week:
                    <md-chip class="orange">{{ total.finishing_next_week }}</md-chip>
                  </b> <br>
                  <b>Finishing in 2 Weeks:
                    <md-chip class="green">{{ total.finishing_next_next_week }}</md-chip>
                  </b> <br>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>

        <div class="md-layout-item">
          <md-card>
            <md-card-content>
              <div class="md-layout text-left">
                <div class="md-layout-item">
                  <b>Enrolled: {{ total.enrolled }}</b><br>
                  <b>Pre-enrolled: {{ total.preEnrolled }}</b><br>
                  <b>Holidays: {{ total.holidays }}</b><br>
                  <b>Inactive: {{ total.inactive }}</b><br>
                  <b>On-Hold: {{ total.onHold }}</b> <br>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div
          v-for="(item, index) in classrooms"
          :key="index"
          class="md-layout-item md-size-25 md-small-size-50 md-xsmall-size-100"
        >
          <class-card :item="item" />
        </div>
      </div>

      <contingency-tab
        v-if="classrooms.length !== 0"
        :data="studentsByStatus"
      />
    </template>
  </div>
</template>

<script>
import downloadFile from '@/utils/downloadFile';
import ClassCard from './ClassCard.vue';
import ContingencyTab from './ContingencyTab.vue';
import ContingencyFilters from './ContingencyFilters.vue';

export default {
  components: {
    ClassCard,
    ContingencyTab,
    ContingencyFilters,
  },
  data() {
    return {
      loading: true,
      fileLoading: true,
      classrooms: [],
      total: {
        available: 0,
        enrolled: 0,
        starting_next_week: 0,
        finishing_this_week: 0,
        finishing_next_week: 0,
        finishing_next_next_week: 0,
        holidays: 0,
        inactive: 0,
        onHold: 0,
        preEnrolled: 0,
      },
      studentsByStatus: {},
    };
  },
  mounted() {
    // Initializing component with filters included.
    // This will trigger the function getClasses
    // with the filters coming from contingencyFilters component
    this.$refs.filters.filter();
  },
  methods: {
    getClasses(filters) {
      if (filters.download) {
        this.$refs.filters.fileLoading(true);

        this.request(this.$API.CONTINGENCY_MAP, 'download', filters, ({ data }) => {
          const filename = `Contingency_Map_${filters.date}.pdf`;
          downloadFile(data, filename);
        }, () => {
          this.$refs.filters.fileLoading(false);
        });
      } else {
        this.loading = true;
        this.request(this.$API.CONTINGENCY_MAP, 'get', { ...filters }, ({ data }) => {
          this.classrooms = data.classrooms;
          this.total = data.total;
          this.studentsByStatus = data.studentsByStatus;
        }, () => {
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.green {
  background-color: green !important;
}
.orange {
  background-color: orange !important;
}
.red {
  background-color: red !important;
}
.md-chip {
  margin: 0;
}

</style>
